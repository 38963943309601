.title{
    font-size: 50px;
    font-weight: bold;
    margin: 30px;
    text-align: center;
}
.subtitle{
    font-size: 20px;
    font-weight: lighter;
    margin: 10px;
    text-align: center;
}