.contact_card{
display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    /*center card*/
    margin: 0 auto;
    height: 100%;
    padding: 1rem;
    background-color: #1975d0;
    color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
/*    Make font more readable, differentiate between l and I. make it modern though!*/
    font-family: 'Segoe UI Semilight', sans-serif;
    font-size: 1.2rem;
}
