.project{
/*    center  EVERYTHING */
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: white;
    transition: 0.3s;
    max-width: 80%;
/*    center with margin*/
    margin: 20px auto;
}