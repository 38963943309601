.code_card{
/*    grey background*/
    background-color: #282c34;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 20px;
    transition: 0.3s;
    color: #ffffff;
}

.code_title{
/*    small title, centered and light*/
    font-size: 20px;
    font-weight: lighter;
    margin: 10px;
    text-align: center;
}