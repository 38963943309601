.desc{
    font-size: 1.1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: #333;
    font-weight: 400;
    line-height: 1.5;
    font-family: 'Roboto', sans-serif;
    text-align: left;
    padding: 0 1rem;
}

.je_title{
    font-size: 50px;
    font-weight: normal;
    margin: 30px 30px 0;
    text-align: center;
}

.je_desc{
    font-size: 15px;
    font-weight: normal;
    margin: 10px;
/*    make text on left side*/
    text-align: left;
}
.date{
    font-size: 15px;
    font-weight: lighter;
    text-align: center;
}
.je2_screenshot{
{/*    image is 1920x1080, resize as the size of the external card changes*/}
    width: 100%;
    height: auto;
}